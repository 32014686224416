.contact {
  background-color: #fff;
  padding-bottom: 14%;
  .palmatomLogo {
    position: absolute;
    left: 0.52rem;
    top: 0.52rem;
    width: 2.3rem;
  }
  .logo {
    margin-top: 7%;
    position: relative;
    overflow: hidden;
    .contact_box {
      position: absolute;
      left: 3.32rem;
      right: 11rem;
      top: 22%;
      bottom: 20%;
      color: #3f4447;
      font-size: 0.36rem;
      font-family: "Montserrat","PingFang SC Regular";
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .line {
        width: 0.28rem;
      }
      .address {
        color: #3f4447;
        font-size: 0.18rem;
        font-family: "PingFang SC Regular";
      }
    }
    .img {
      position: absolute;
      left: 8.67rem;
      right: 0;
      top: 0;
      bottom: 0;
      width: 8.16rem;
      .carousel {
        width: 100%;
      }
    }
  }
  .box {
    margin-top: 6%;
    padding: 0 3.32rem;
    color: #3f4447;
    font-size: 0.36rem;
    font-family: "Montserrat","PingFang SC Medium";
    .text {
      font-size: 0.18rem;
      font-family: "PingFang SC";
      line-height: 2;
    }
    .line {
      width: 0.28rem;
      margin-top: 1.5%;
      margin-bottom: 1.5%;
    }
  }
}
@primary-color: #83898c;