.drawer-menu{
  background-color: #e6eaed;
  padding-left: 13%;
  position: relative;
  .ant-drawer-header .ant-drawer-close {
    padding: 0;
  }
  .ant-menu-item a {
    color: #666F75;
    font-size: 0.18rem;
    font-family: 'Montserrat';
  }
  .ant-menu-sub .ant-menu-item a {
    color: #666F75;
    font-size: 0.14rem;
    font-family: 'Montserrat';
  }
  .ant-menu-item a:hover {
    color: #2F3335 !important;
  }
  .ant-menu-item.ant-menu-item-only-child {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
  }
  .ant-menu-submenu-title:first-child {
    font-size: 0.18rem;
  }
  .ant-menu-submenu-title {
    color: #666F75;
    font-size: 0.14rem;
    font-family: 'Montserrat';
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
  }
  .ant-menu-submenu-title:hover {
    color: #2F3335 !important;
  }

  .ant-menu-submenu-title .anticon {
    color: #666F75 !important;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: #e6eaed;
  }
  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: #e6eaed;
  }
}

.admin {
  .menuIcon {
    position: fixed;
    top: 0.34rem;
    right: 0.46rem;
    padding: 0;
    width: 0.26rem;
    z-index: 999;
  }
  
  .footer {
    background-color: #f6f7f9;
    padding: 3% 6% 3%;
    .divider {
      margin-top: 0.18rem;
      margin-bottom: 0.16rem;
    }
    .text {
      color: #3f4447;
      font-size: 0.18rem;
      font-family: "PingFang SC Regular";
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .button_left {
        word-break: break-all;
        word-wrap: break-word;
        text-align: justify;
        .button {
          color: #3f4447;
          font-size: 0.18rem;
          font-family: "PingFang SC Regular";
          border: none;
        }
        .button-five {
          margin-left: 0.2rem;
        }
      }
      .version {
        text-align: right;
      }
    }
  }
}
@primary-color: #83898c;