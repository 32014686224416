.home {
  background-color: #fff;
  position: relative;
  .custom-bg {
    width: 100%;
    height: 500px;
  }
  .palmatomLogo {
    position: absolute;
    left: 0.52rem;
    top: 0.52rem;
    width: 2.3rem;
  }
  .paragraph {
    position: relative;
    top: 50px;
    .palmatom {
      font-size: 0.48rem;
      font-family: "Montserrat Medium";
      color: #2f3335;
      text-align: center;
      margin-top: 8%;
    }
    .line {
      font-size: 0.18rem;
      color: #707374;
      text-align: center;
      margin-top: 2%;
      margin-bottom: 2.8%;
    }
    .p {
      text-align: center;
      color: #2f3335;
      font-size: 0.18rem;
      font-family: "PingFang SC Regular";
      line-height: 2;
      padding: 0 0.2rem;
    }
  }
  .section{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 6%;
    .section_part {
      width: 3.36rem;
      min-width: 195px;
      margin: 2%;
      position: relative;
      top: 50px;
      .imgBox {
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .text {
        color: #2f3335;
        font-size: 0.16rem;
        font-family: "PingFang SC Regular";
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 2;
        word-break: break-all;
        word-wrap: break-word;
        text-align: justify;
        .title {
          padding-top: 5%;
          font-size: 0.28rem;
          font-family: "Montserrat","PingFang SC Medium";
          color: #3f4447;
        }
      }
    }
  }
  .designBgImg {
    width: 6.05rem;
    min-width: 350px;
    min-height: 100px;
    height: 1.73rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-image: url(./images/squares.png);
    background-size: 100% 100%;
    .designContent {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .design_img {
        margin-bottom: 0.1rem;
      }
      .design_text {
        color: #2f3335;
        font-size: 0.16rem;
        font-family: "PingFang SC Regular";
        padding: 0 0.05rem;
        text-align: center;
        line-height: 3;
      }
      .design_text:last-child {
        line-height: 1;
      }
    }
  }
  .video-react video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .video-react .video-react-big-play-button {
    display: none;
  }
  .video-react .video-react-control-bar {
    display: flex; // 此处设置控制栏一直显示(控制栏默认是隐藏的，hover时才显示)
    background-color: rgba(00, 00, 00, 0.1);
  }
  // em => 相对单位，相对父元素的font-size来改变
  // 此处video-react固定font-size为10px，1em = 10px
  // .video-react .video-react-volume-level::before {
  //   font-size: 1.2em;
  //   // top: -0.4em; // -0.3
  // }
  // .video-react .video-react-play-progress:before {
  //   font-size: 1.2em;
  //   // top: -0.4em; // -0.333...
  // }
}
@primary-color: #83898c;