.join {
  background-color: #fff;
  padding-bottom: 12%;
  .palmatomLogo {
    position: absolute;
    left: 0.52rem;
    top: 0.52rem;
    width: 2.3rem;
  }
  .joinLogo {
    position: absolute;
    left: 80.83%;
    top: 27.16%;
    width: 1.86rem;
  }
  .paragraph {
    margin-top: 8%;
    padding: 0 25%;
    color: #3f4447;
    font-size: 0.24rem;
    font-family: "PingFang SC Regular";
    position: relative;
    top: 50px;
    .text {
      line-height: 2;
      word-break: break-all;
      word-wrap: break-word;
      text-align: justify;
    }
    .line {
      width: 0.28rem;
      color: #707374;
      margin-top: 2%;
      margin-bottom: 2%;
    }
  }
}
@primary-color: #83898c;