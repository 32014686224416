.medium {
  background-color: #fff;
  position: relative;
  .palmatomLogo {
    position: absolute;
    left: 0.52rem;
    top: 0.52rem;
    width: 2.3rem;
  }
  .mediumLogo {
    position: absolute;
    left: 62%;
    top: 35%;
    width: 1.85rem;
  }
  .paragraph {
    position: relative;
    top: 50px;
    margin-top: 8%;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      .logoImg {
        width: 5.02rem;
      }
    }
    .title {
      text-align: center;
      color: #2f3335;
      font-size: 0.32rem;
      font-family: "Montserrat","PingFang SC Medium";
      margin-top: 0.2rem;
    }
    .line {
      font-size: 0.21rem;
      color: #707374;
      text-align: center;
      margin-top: 2%;
      margin-bottom: 4%;
    }
  }

  .section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .section_part {
      display: flex;
      width: 60%;
      margin-bottom: 0.4rem;
      position: relative;
      top: 50px;
      .text {
        width: 30%;
        padding-right: 0.15rem;
        .subtitle {
          color: #3f4447;
          font-size: 0.28rem;
          font-family: "Montserrat","PingFang SC Medium";
          line-height: 1;
        }
        .subline {
          color: #707374;
          font-size: 0.08rem;
          margin-top: 13%;
          margin-bottom: 13%;
        }
        .p {
          font-size: 0.16rem;
          font-family: "PingFang SC";
          color: #2f3335;
        }
      }
      .img {
        width: 8.1rem;
        .carousel {
          width: 100%;
        }
      }
    }
  }

  .service {
    margin-top: 6%;
    width: 100%;
    font-size: 0.32rem;
    font-family: "Montserrat","PingFang SC Medium";
    color: #3f4447;
    text-align: center;
    padding-top: 1.08rem;
    background-image: url(./images/serviceUser.png);
    background-size: 100% 100%;
  }
}
@primary-color: #83898c;