.agriculture {
  background-color: #fff;
  padding-bottom: 8%;
  .palmatomLogo {
    position: absolute;
    left: 0.52rem;
    top: 0.52rem;
    width: 2.3rem;
  }
  .agricultureLogo {
    position: absolute;
    left: 71%;
    top: 43%;
    width: 1.59rem;
  }
  .paragraph {
    position: relative;
    top: 50px;
    margin-top: 8%;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      .logoImg {
        width: 5.7rem;
      }
    }
    .title {
      text-align: center;
      color: #2f3335;
      font-size: 0.32rem;
      font-family: "Montserrat","PingFang SC Medium";
      margin-top: 0.2rem;
    }
    .line {
      font-size: 0.18rem;
      color: #707374;
      text-align: center;
      margin-top: 2%;
      margin-bottom: 2%;
    }
  
    .p {
      text-align: center;
      color: #3f4447;
      font-size: 0.18rem;
      font-family: "PingFang SC";
      padding: 0 0.2rem;
    }
  }

  .section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4%;
    .section_part {
      width: 5.98rem;
      margin: 0.4rem;
      position: relative;
      top: 30px;
      .imgBox {
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .text {
        text-align: center;
        color:#3f4447;
        font-size: 0.16rem;
        font-family: "PingFang SC";
        line-height: 4;
      }
    }
  }
}
@primary-color: #83898c;