.about {
  width: 100%;
  height: 100%;
  background-color: #cacacb;
  position: relative;

  video {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 8.8rem;
    object-fit: fill;
    opacity: 0.5;
  }

  .logo {
    width: 62.5%;
    position: absolute;
    left: 0;
    right: 0;
    top: 13.89%;
    bottom:0;
    margin: auto;
  }

  .about_box {
    height: 150px;
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    bottom: 0;
    margin: auto;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    .aboutBtn {
      color: #83898C;
      font-size: 0.22rem;
      font-weight: 300;
      font-family: "PingFang";
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    .arrowImg {
      width: 0.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.14rem;
    }
  }
  
  .toggleBtn {
    font-size: 0.14rem;
    font-family: "PingFang";
    color: #83898C;
    position: absolute;
    left: 0;
    right: 0;
    top: 84%;
    bottom: 0;
    margin: auto;
    text-align: center;
    z-index: 4;
    opacity: 0.5;
    .left {
      font-size: 0.14rem;
      color: #83898C;
      margin-right: 0.14rem;
      opacity: 0.8;
    }
    .right {
      font-size: 0.14rem;
      color: #83898C;
      margin-left: 0.14rem;
      opacity: 0.8;
    }
  }
}
@primary-color: #83898c;